var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"title"},[_vm._v("Delegataire")]),_c('h3',{attrs:{"id":"ajout"}},[_vm._v("Ajouter un Delegataire")]),_c('h4',[_vm._v(" Bienvenue chez Batigo ! Pour ajouter un délégataire, veuillez suivre les étapes ci-dessous : ")]),_vm._m(0),_c('p',[_vm._v(" vous pouvez utiliser les placeholders %DELEGATAIRE%, %SIREN%, et %MONTANT% dans les paragraphes \"Montant CEE\" ou \"Montant MaPrimeRenov\" pour inclure automatiquement les informations du délégataire dans le devis ou la facture. ")]),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1683708454_Ajouter un délégataire.mp4`,"controls":""}})]),_c('h3',{attrs:{"id":"edit"}},[_vm._v("Modifier un Delegataire")]),_c('h4',[_vm._v(" Si vous souhaitez modifier un délégataire, veuillez suivre les étapes ci-dessous : ")]),_vm._m(1),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1683708419_Modifier un délégataire.mp4`,"controls":""}})]),_c('p',[_vm._v(" Veuillez noter que si le délégataire a déjà été utilisé dans un devis, une facture, les modifications apportées à ses informations ne seront pas reflétées dans les documents existants. Vous devrez mettre à jour manuellement les informations dans ces documents si nécessaire. ")]),_c('h3',{attrs:{"id":"delete"}},[_vm._v("Supprimer un Delegataire")]),_c('h4',[_vm._v(" Si vous souhaitez supprimer un délégataire , veuillez suivre les étapes ci-dessous : ")]),_vm._m(2),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1683708363_Supprimer un délégataire.mp4`,"controls":""}})]),_c('p',[_vm._v(" N'oubliez pas que cette action est irréversible et que toutes les informations associées au type de pièce seront également supprimées. ")]),_c('div',{staticClass:"bulle"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"33","height":"33","viewBox":"0 0 33 33"}},[_c('g',{attrs:{"id":"Icon_feather-info","data-name":"Icon feather-info","transform":"translate(-1.5 -1.5)"}},[_c('path',{attrs:{"id":"Tracé_78064","data-name":"Tracé 78064","d":"M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78065","data-name":"Tracé 78065","d":"M18,24V18","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78066","data-name":"Tracé 78066","d":"M18,12h0","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}})])]),_vm._v(" Nous espérons que ces instructions vous ont été utiles. Si vous avez des questions ou des problèmes avec un Delegataire, n'hésitez pas à nous contacter via notre email de contact. Merci de nous faire confiance et à bientôt sur Batigo ! ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez dans la section \"Délégataires\" de l'interface.")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Nouveau\" pour ajouter un nouveau délégataire. ")]),_c('li',[_vm._v(" Remplissez le formulaire avec les informations du délégataire (nom,SIREN, etc.). ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour sauvegarder les informations du délégataire. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la page de gestion des délégataires.")]),_c('li',[_vm._v(" Recherchez le délégataire que vous souhaitez modifier à l'aide des filtres de recherche ou en naviguant dans la liste des délégataires. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Modifier\" à côté du délégataire concerné. ")]),_c('li',[_vm._v(" Modifiez les informations que vous souhaitez changer dans le formulaire de modification. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour sauvegarder les modifications. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la liste des délégataires.")]),_c('li',[_vm._v(" Trouvez le délégataire que vous souhaitez supprimer et cliquez sur le bouton \"Supprimer\". ")]),_c('li',[_vm._v(" Une boîte de dialogue de confirmation apparaîtra pour confirmer que vous souhaitez vraiment supprimer le délégataire. Cliquez sur \"Supprimer\" pour continuer. ")])])
}]

export { render, staticRenderFns }